import './App.css';
import { Box, ChakraProvider } from '@chakra-ui/react'
import HomePage from './pages/HomePage';

function App() {
  return (
    <ChakraProvider>
      <Box
        width="100vw"
        height="100vh"
      >
        <HomePage />
      </Box>
    </ChakraProvider>
  );
}

export default App;
