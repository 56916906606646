import {Box, Text, Spinner, Center, Divider, SimpleGrid, Link} from '@chakra-ui/react';
import NodeCarousel from "./NodeCarousel";
import {ExternalLinkIcon} from "@chakra-ui/icons";

const ConfigPreview = ({ config, loading, initPhase, isError }) => {
  const huggingEmoji = '🤗';

  if (loading) {
    return (
      <Center height="100%">
        <Box className="neumorphic-box" w="40vh" h="40vh" p={4}>
          <Center height="100%">
            <Box textAlign="center">
              <Spinner
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='blue.500'
                size='xl'
              />
              <Text mt={4} fontSize={20} color="white">Loading ...</Text>
            </Box>
          </Center>
        </Box>
      </Center>
    );
  }

  if (initPhase) {
    return (
      <Center height="100%">
        <Box className="neumorphic-box" w="50vh" h="50vh" p={4}>
          <Center height="100%">
            <SimpleGrid columns={1}>
              <Box textAlign="center">
                <Text color="white" fontSize={20}>Your config will appear here</Text>
                <Text fontSize="50px" mt={2}>{huggingEmoji}</Text>
              </Box>
              <Divider></Divider>
              <Box textAlign="center">
                <Text color="white" fontSize={14}>Not sure which partitions to choose for your workload?</Text>
              </Box>
              <Divider></Divider>
              <Box textAlign="center">
                <Text fontSize={25} mt={2}>
                  <Link color='yellow' href='https://github.com/kruize/autotune' isExternal>
                    Try Kruize <ExternalLinkIcon mx='2px' />
                  </Link>
                </Text>
              </Box>
            </SimpleGrid>
          </Center>
        </Box>
      </Center>
    );
  }

  if (isError) {
    return (
      <Center height="100%">
        <Box className="neumorphic-box" w="50vh" h="50vh" p={4}>
          <Center height="100%">
            <Box textAlign="center">
              <Text color="red" fontSize={30}>{config.title}</Text>
              <Text fontSize={20} mt={2}>{config.message}</Text>
            </Box>
          </Center>
        </Box>
      </Center>
    );
  }

  const nodeItems = Object.entries(config.data.node_config).map(([nodeKey, nodeData]) => ({
    ...nodeData,
    nodeKey
  }));

  return (
    <Center width="100%" height="100%">
      <Box
        display="flex"
        flexDirection={{ base: 'column', md: 'row' }}
        overflowX={{ base: 'auto', md: 'scroll' }}
        pt={4}
        pb={4}
        width="100%"
        maxHeight="80vh"
      >
        <NodeCarousel items={nodeItems} extra_nodes={config.data.extra_nodes} extra_gpus={config.data.extra_gpus}/>
      </Box>
    </Center>
  );
};

export default ConfigPreview;
