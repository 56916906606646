import {
  Box,
  Text,
  Button,
  Radio,
  RadioGroup,
  Stack,
  Select,
  Input,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  SimpleGrid,
  Spinner, background
} from '@chakra-ui/react';
import {ArrowForwardIcon, CloseIcon} from '@chakra-ui/icons'
import { useState, useEffect } from 'react';
import axios from 'axios';

const ConfigForm = ({ onConfigChange, onLoadingChange, onInitPhaseChange, onIsErrorChange }) => {
  const [metadata, setMetadata] = useState({});
  const [cloudProviders, setCloudProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState('');
  const [instances, setInstances] = useState([]);
  const [selectedInstance, setSelectedInstance] = useState('');
  const [numNodes, setNumNodes] = useState(1);
  const [gpus, setGpus] = useState(0);
  const [customInstanceName, setCustomInstanceName] = useState('');
  const [customGpuType, setCustomGpuType] = useState('');
  const [customGpuCount, setCustomGpuCount] = useState(1);
  const [customInstanceCount, setCustomInstanceCount] = useState(1);
  const [gpuMetadata, setGpuMetadata] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [migPartitions, setMigPartitions] = useState([]);
  const [migCounts, setMigCounts] = useState({});
  const [formError, setFormError] = useState('');

  useEffect(() => {
    const fetchMetadata = async () => {
      try {
        const response = await axios.get('https://mig-configurator.fly.dev/service/mig-config/get_metadata');
        setMetadata(response.data);
        const providers = Object.keys(response.data.cloud_providers);
        setCloudProviders(providers);
        setGpuMetadata(response.data.gpu_metadata);
        setLoading(false);
      } catch (error) {
        setError('Server down, please try after some time');
        setLoading(false);
      }
    };

    fetchMetadata();
  }, []);

  useEffect(() => {
    if (metadata && selectedProvider && selectedProvider !== 'Other') {
      const providerInstances = metadata.cloud_providers[selectedProvider]?.instances || [];
      setInstances(providerInstances);
      setSelectedInstance('');
      setNumNodes(1);
      setGpus(0);
      setMigPartitions([]);
      setMigCounts({});
    } else {
      setInstances([]);
    }
  }, [selectedProvider, metadata]);

  useEffect(() => {
    if (selectedProvider === 'Other' && customGpuType) {
      const gpuInfo = gpuMetadata[customGpuType];
      if (gpuInfo) {
        setMigPartitions(gpuInfo.mig_partitions);
      }
    } else if (selectedInstance && selectedProvider !== 'Other') {
      const instance = instances.find((inst) => inst.name === selectedInstance);
      if (instance) {
        const gpuInfo = gpuMetadata[instance.gpu.name];
        if (gpuInfo) {
          setMigPartitions(gpuInfo.mig_partitions);
        }
      }
    }
  }, [selectedProvider, customGpuType, selectedInstance, instances, gpuMetadata]);

  const clearAllExceptProvider = () => {
    setSelectedInstance('');
    setNumNodes(1);
    setGpus(0);
    setCustomInstanceName('');
    setCustomGpuType('');
    setCustomGpuCount(1);
    setCustomInstanceCount(1);
    setMigPartitions([]);
    setMigCounts({});
  }

  const handleProviderChange = (value) => {
    setSelectedProvider(value);
    clearAllExceptProvider();
  };

  const handleInstanceChange = (instanceName) => {
    setSelectedInstance(instanceName);
    const instance = instances.find((inst) => inst.name === instanceName);
    setGpus(instance ? instance.gpu.count : 0);
    if (instance) {
      const gpuInfo = gpuMetadata[instance.gpu.name];
      if (gpuInfo) {
        setMigPartitions(gpuInfo.mig_partitions);
        setMigCounts({});
      }
    }
    setFormError('');
  };

  const handleGpuChange = (value) => {
    setCustomGpuType(value);
    const gpuInfo = gpuMetadata[value];
    if (gpuInfo) {
      setMigPartitions(gpuInfo.mig_partitions);
      setMigCounts({});
    }
    setFormError('');
  };

  const handleMigCountChange = (partition, value) => {
    setMigCounts(prevCounts => ({
      ...prevCounts,
      [partition]: parseInt(value, 10)
    }));
    setFormError('');
  };

  const validateForm = () => {
    if (!selectedProvider) {
      return 'Please select a cloud provider.';
    }
    if (selectedProvider === 'Other') {
      if (!customInstanceName) {
        return 'Please enter Instance Name';
      }
      if (customInstanceCount <= 0) {
        return 'Instance Count cannot be <= 0';
      }
      if (!customGpuType) {
        return 'Please select the GPU Type'
      }

      if (customGpuCount <= 0) {
        return "GPU's cannot be <= 0"
      }

      if (Object.values(migCounts).every(count => count <= 0)) {
        return 'At least one MIG partition count must be greater than 0.';
      }
    } else {
      if (!selectedInstance) {
        return "Please select the Instance"
      }

      if (numNodes <= 0) {
        return "Node Count cannot be <= 0"
      }

      if (gpus <= 0) {
        return "GPU's cannot be <= 0";
      }
      if (Object.values(migCounts).every(count => count <= 0)) {
        return 'At least one MIG partition count must be greater than 0.';
      }
    }
    return '';
  };

  const handleSubmit = async () => {
    const errorMessage = validateForm();
    if (errorMessage) {
      setFormError(errorMessage);
      return;
    }

    const data = selectedProvider === 'Other'
      ? {
        cloud_provider: "Other",
        instance_name: customInstanceName,
        node_count: customInstanceCount,
        gpu_type: customGpuType,
        gpu_count: customGpuCount,
        profiles_needed: migCounts
      }
      : {
        cloud_provider: selectedProvider,
        instance_name: selectedInstance,
        gpu_type: instances.find((inst) => inst.name === selectedInstance)?.gpu?.name || '',
        node_count: numNodes,
        gpu_count: gpus,
        profiles_needed: migCounts
      };
    onInitPhaseChange(false);
    onLoadingChange(true);
    const response = await axios.post('https://mig-configurator.fly.dev/service/mig-config/get_config', data);
    onLoadingChange(false);
    if (response.data.status === "error" && response.data.code === 400) {
      onIsErrorChange(true)
      onConfigChange(response.data);
    } else {
      onIsErrorChange(false)
      onConfigChange(response.data);
    }
  };

  const handleReset = () => {
    setSelectedProvider('');
    clearAllExceptProvider();
    onLoadingChange(false);
    onInitPhaseChange(true);
  };

  if (loading) {
    return (
      <Box
        width="100%"
        height="100%"
        className="neumorphic-box"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        p={4}
      >
        <Spinner
          thickness='4px'
          speed='0.65s'
          emptyColor='gray.200'
          color='blue.500'
          size='xl'
        />
        <Text> Please wait while we load the setup ... </Text>
      </Box>
    );
  }

  if (error) {
    return <Text>{error}</Text>;
  }

  return (
    <Box
      width="100%"
      minHeight="90vh"
      height="100%"
      className="neumorphic-box"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      p={4}
    >
      <Text
        bgGradient='linear(to-l, #a8ff78, #78ffd6)'
        bgClip='text'
        fontSize='3xl'
        fontWeight='extrabold'
      >
        GPU Capacity Planner
      </Text>

      <fieldset style={{width: "90%", border: "1px solid #78ffd6", borderRadius: "10px", padding: "10px", marginTop: "15px"}}>
        <legend style={{paddingLeft: "5px", paddingRight: "5px"}}>Cloud Provider *</legend>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          pb={3}
        >
          <RadioGroup onChange={handleProviderChange} value={selectedProvider}>
            <Stack direction="row">
              {cloudProviders.map(provider => (
                <Radio colorScheme='teal' key={provider} value={provider}>
                  {provider}
                </Radio>
              ))}
              <Radio colorScheme='teal' value="Other">Other</Radio>
            </Stack>
          </RadioGroup>
        </Box>

      </fieldset>

      {selectedProvider && selectedProvider !== 'Other' && (
        <>
        <fieldset style={{width: "90%", border: "1px solid #78ffd6", borderRadius: "10px", padding: "10px", marginTop: "15px"}}>
          <legend style={{paddingLeft: "5px", paddingRight: "5px"}}>Instance Details *</legend>
          <SimpleGrid width="100%" columns={2} spacing={2}>
            <Box
              height='80px'
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Select
                placeholder="Select instance"
                value={selectedInstance}
                onChange={(e) => handleInstanceChange(e.target.value)}
                bg="#383838"
                color="white"
              >
                {instances.map(instance => (
                  <option style={{ background: "#383838", color: "#FFFFFF"}} key={instance.name} value={instance.name}>
                    {instance.name}
                  </option>
                ))}
              </Select>
            </Box>
            <Box
              height='80px'
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-evenly"
            >
              <Text>Count: </Text>
              <NumberInput
                width="100px"
                value={numNodes}
                onChange={(valueString) => setNumNodes(parseInt(valueString, 10))}
                min={1}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </Box>
          </SimpleGrid>
        </fieldset>



          <Text mt={4} color="white">
            GPUs per Instance : {gpus}
          </Text>

          {migPartitions.length > 0 && (
            <fieldset style={{width: "90%", border: "1px solid #78ffd6", borderRadius: "10px", padding: "10px", marginTop: "10px"}}>
              <legend style={{paddingLeft: "5px", paddingRight: "5px"}}>MIG Partition Details *</legend>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <SimpleGrid width="100%" columns={2} spacingX={2}>
                  {migPartitions.map((partition) => (
                    <Box
                      height='50px'
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-evenly"
                    >
                      <Text color="white">{partition}</Text>
                      <NumberInput
                        width="100px"
                        value={migCounts[partition] || 0}
                        onChange={(valueString) => handleMigCountChange(partition, valueString)}
                        min={0}
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </Box>
                  ))}
                </SimpleGrid>
              </Box>
            </fieldset>
          )}
        </>
      )}

      {selectedProvider === 'Other' && (
        <>
          {/* Instance Details */}
          <fieldset style={{width: "90%", border: "1px solid #78ffd6", borderRadius: "10px", padding: "10px", marginTop: "10px"}}>
            <legend style={{paddingLeft: "5px", paddingRight: "5px"}}>Instance Details *</legend>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <SimpleGrid width="100%" columns={2} spacing={2}>
                <Box
                  height='50px'
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Input
                    placeholder="Enter custom instance name"
                    value={customInstanceName}
                    onChange={(e) => setCustomInstanceName(e.target.value)}
                    maxLength={30}
                    _placeholder={{ opacity: 1, color: 'white' }}
                  />
                </Box>
                <Box
                  height='50px'
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-evenly"
                >
                  <Text>Count: </Text>
                  <NumberInput
                    width="100px"
                    value={customInstanceCount}
                    onChange={(valueString) => setCustomInstanceCount(parseInt(valueString, 10))}
                    min={1}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Box>
              </SimpleGrid>
            </Box>
          </fieldset>

          {/* GPU Details */}
          <fieldset style={{width: "90%", border: "1px solid #78ffd6", borderRadius: "10px", padding: "10px", marginTop: "10px"}}>
            <legend style={{paddingLeft: "5px", paddingRight: "5px"}}>GPU Details * </legend>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <SimpleGrid width="100%" columns={2} spacing={2}>
                <Box
                  height='50px'
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Select
                    placeholder="Select GPU type"
                    value={customGpuType}
                    onChange={(e) => handleGpuChange(e.target.value)}
                  >
                    {Object.keys(gpuMetadata).map(gpuType => (
                      <option key={gpuType} value={gpuType}>
                        {gpuMetadata[gpuType].metadata.name}
                      </option>
                    ))}
                  </Select>
                </Box>
                <Box
                  height='50px'
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-evenly"
                >
                  <Text>Count: </Text>
                  <NumberInput
                    width="100px"
                    value={customGpuCount}
                    onChange={(valueString) => setCustomGpuCount(parseInt(valueString, 10))}
                    min={1}
                    max={16}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Box>
              </SimpleGrid>
            </Box>
          </fieldset>



          {migPartitions.length > 0 && (
            <fieldset style={{width: "90%", border: "1px solid #78ffd6", borderRadius: "10px", padding: "10px", marginTop: "10px"}}>
              <legend style={{paddingLeft: "5px", paddingRight: "5px"}}>MIG Partition Details *</legend>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <SimpleGrid width="100%" columns={2} spacingX={2}>
                  {migPartitions.map((partition) => (
                    <Box
                      height='50px'
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-evenly"
                    >
                      <Text color="white">{partition}</Text>
                      <NumberInput
                        width="100px"
                        value={migCounts[partition] || 0}
                        onChange={(valueString) => handleMigCountChange(partition, valueString)}
                        min={0}
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </Box>
                  ))}
                </SimpleGrid>
              </Box>
            </fieldset>
          )}
        </>
      )}

      {formError && <Text color="red.300" mt={4}>{formError}</Text>}

      <SimpleGrid width="70%" columns={2} spacingX={10}>
        <Button
          colorScheme="red"
          onClick={handleReset}
          rightIcon={<CloseIcon w={3} h={3} />}
          mt={4}
        >
          Clear
        </Button>

        <Button onClick={handleSubmit}
                colorScheme="teal"
                mt={4}
                rightIcon={<ArrowForwardIcon w={5} h={5} />}
        >
          Get GPU config
        </Button>
      </SimpleGrid>
    </Box>
  );
};

export default ConfigForm;
