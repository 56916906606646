// src/pages/HomePage.js
import {Box, Center} from '@chakra-ui/react';
import { useState } from 'react';
import ConfigForm from '../components/ConfigForm';
import ConfigPreview from '../components/ConfigPreview';
import {Icon} from "@chakra-ui/icons";
import {MdFavorite} from "react-icons/md";


const HomePage = () => {

  const [config, setConfig] = useState(null);
  const [loading, setLoading] = useState(false);
  const [initPhase, setInitPhase] = useState(true);
  const [isError, setIsError] = useState(false);

  const handleConfigChange = (newConfig) => {
    setConfig(newConfig);
  };

  const handleLoadingChange = (newLoading) => {
      setLoading(newLoading);
  }

  const handleInitPhaseChange = (newInitPhase) => {
    if (newInitPhase !== initPhase) {
      setInitPhase(newInitPhase);
    }
  }

  const handleErrorChange = (error) => {
    setIsError(error)
  };

  const BuyMeACoffeeButton = () => {
    const buttonStyles = {
      position: 'relative',
      zIndex: '999',
    };

    return (
      <Box style={buttonStyles}>
        <a
          href="https://www.buymeacoffee.com/bharathappali"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
            alt="Buy Me a Coffee"
            style={{ height: '50px', width: '210px' }}
          />
        </a>
      </Box>
    );
  };

  return (
    <Box
      width="100%"
      minHeight="100vh"
      height="100vh"
      display="flex"
      flexDirection={{ base: 'column', lg: 'row' }}
      alignItems="center"
      justifyContent="center"
      bg="#383838"
      color="white"
      overflow={{ base: 'scroll', lg: 'hidden' }}
    >
      <Box width={{ base: '100%', lg: '40%' }} height="100vh" p={{ base: 2, lg: 10}}>
        <ConfigForm onConfigChange={handleConfigChange}
                    onLoadingChange={handleLoadingChange}
                    onInitPhaseChange={handleInitPhaseChange}
                    onIsErrorChange={handleErrorChange}
        />
      </Box>

      <Box width={{ base: '100%', lg: '60%' }} height="100vh" pt={{ base: 2, lg: 10}} pb={{ base: 2, lg: 10}} pr={{ base: 2, lg: 10}}>
        <Box
          width="100%"
          height="100%"
          className="neumorphic-box"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-between"
          pt={4}
          pb={4}
        >
          <Center>Made with &nbsp; <Icon as={MdFavorite} color='red.500' w={5} h={5}/> &nbsp; for the world</Center>
          <ConfigPreview config={config} loading={loading} initPhase={initPhase} isError={isError} />
          <Center>
            Like my work? &nbsp;&nbsp;&nbsp;
            <BuyMeACoffeeButton />
          </Center>
        </Box>

      </Box>
    </Box>
  );
};

export default HomePage;
