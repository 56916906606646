import React, {useEffect, useRef} from 'react';
import {
  Box,
  IconButton,
  Text,
  HStack,
  Tooltip,
  SimpleGrid,
  Center,
  Button,
  Stack, Badge
} from '@chakra-ui/react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import {CopyIcon} from "@chakra-ui/icons";
import {Prism as SyntaxHighlighter} from "react-syntax-highlighter";
import {tomorrow} from "react-syntax-highlighter/dist/cjs/styles/prism";
import {useClipboard} from "@chakra-ui/hooks";

const NodeCarousel = ({ items, extra_nodes, extra_gpus }) => {
  const { onCopy, value, setValue, hasCopied } = useClipboard('');
  const needExtraResources = extra_nodes > 0 || extra_gpus > 0;

  const handleCopy = (yaml_config) => {
    setValue(yaml_config);
    onCopy(yaml_config);
  }

  const containerRef = useRef(null);

  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: -400, // Adjust based on your card width
        behavior: 'smooth',
      });
    } else {
      console.log('containerRef is not defined');
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: 400,
        behavior: 'smooth',
      });
    } else {
      console.log('containerRef is not defined');
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowRight') {
      scrollRight();
    } else if (event.key === 'ArrowLeft') {
      scrollLeft();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <SimpleGrid width="100%" columns={1}>
      <Center width="100%">
        {needExtraResources ? (
          <>
            {extra_gpus > 0 && `You need ${extra_gpus} additional GPU${extra_gpus > 1 ? 's' : ''}`}
            {extra_nodes > 0 && extra_gpus > 0 && ' '}
            {extra_nodes > 0 && `( ${extra_nodes} additional node${extra_nodes > 1 ? 's' : ''} )`}
          </>
        ) : (
          "No additional resources required"
        )}
      </Center>
      <Box width="100%" display="flex" alignItems="center" justifyContent="center" position="relative">
        <IconButton
          aria-label="Previous"
          icon={<MdChevronLeft />}
          onClick={scrollLeft}
          position="absolute"
          left={2}
          top="50%"
          transform="translateY(-50%)"
          zIndex={1}
        />
        <HStack
          ref={containerRef}
          spacing={4}
          overflowX="scroll"
          overflowY="hidden"
          height="600px"
          maxWidth="50vw"
          maxHeight="600px"
          px={4}
          className="no-scroll-bar"
        >
          {items.map((item, index) => (
            <Box
              width="410px"
              height="510px"
              borderRadius={55}
              boxShadow="md"
              maxWidth="420px"
              maxHeight="520px"
              textAlign="center"
              className="no-scroll-bar"
              bgGradient={item.is_extra_node ? "linear(to-l, #a8ff78, #78ffd6)" : "linear(to-l, #f7ff00, #db36a4)"}
            >
              <Center width="410px" height="100%">
                <Box
                  p={4}
                  width="400px"
                  height="500px"
                  boxShadow="md"
                  maxWidth="400px"
                  maxHeight="500px"
                  textAlign="center"
                  className="no-scroll-bar neumorphic-box"
                >
                  <SimpleGrid w="100%" columns={2} spacing={2}>
                    <Box>
                      <Text fontWeight="bold"
                            fontSize="x-large"
                            bgGradient={item.is_extra_node ? "linear(to-l, #a8ff78, #78ffd6)" : "linear(to-l, #f7ff00, #db36a4)"}
                            bgClip='text'
                      >
                        Node {index}
                      </Text>
                    </Box>
                    <Box>
                      <Center h="100%">
                        <Tooltip label={hasCopied ? "Copied!" : "Copy YAML"}>
                          <Button
                            size="xs"
                            leftIcon={<CopyIcon />}
                            colorScheme='white'
                            variant='outline'
                            onClick={() => handleCopy(item.yaml_config)}
                          >
                            Copy YAML
                          </Button>
                        </Tooltip>
                      </Center>
                    </Box>
                  </SimpleGrid>

                  <Stack pl={2} pb={1} direction='row'>
                    <Badge borderRadius={20} pl={2} pr={2} variant='solid' colorScheme='teal'>Instance: {item.instance_name}</Badge>
                  </Stack>

                  <Stack pl={2} pt={1} direction='row'>
                    <Badge borderRadius={20} pl={2} pr={2} variant='solid' colorScheme='purple'>GPUS: {item.gpu_count}</Badge>
                    <Badge borderRadius={20} pl={2} pr={2} variant='solid' colorScheme={item.is_extra_node ? "red" : "green"}>
                      {item.is_extra_node ? "Required" : "Provisioned"}
                    </Badge>
                  </Stack>

                  <Box className="no-scroll-bar" width="100%" minHeight="380px" maxHeight="380px" overflow="scroll">
                    <SyntaxHighlighter language="yaml" style={tomorrow}>
                      {item.yaml_config}
                    </SyntaxHighlighter>
                  </Box>


                </Box>
              </Center>
            </Box>
          ))}
        </HStack>

        <IconButton
          aria-label="Next"
          icon={<MdChevronRight />}
          onClick={scrollRight}
          position="absolute"
          right={2}
          top="50%"
          transform="translateY(-50%)"
          zIndex={1}
        />
      </Box>
    </SimpleGrid>
  );
};

export default NodeCarousel;
